@import '../../../../scss/theme-bootstrap';

.sticky-footer-chat-block {
  background: $color-off-black;
  padding: 25px;
  z-index: 2;
  letter-spacing: 0.2px;
  text-transform: none;
  width: 100%;
  @include breakpoint($landscape-up) {
    width: auto;
  }
  &__pc {
    border-bottom: none;
    color: $white;
    line-height: get-line-height(17px, 25px);
  }
  &__mobile {
    border-bottom: none;
    color: $white;
    line-height: get-line-height(17px, 25px);
  }
  &-cols {
    @include breakpoint($landscape-up) {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
    }
  }
  &__image {
    display: none;
    @include breakpoint($landscape-up) {
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;
      bottom: 3px;
      margin-#{$rdirection}: 6px;
      img {
        max-width: 150px;
      }
    }
  }
  .icon {
    padding-#{$ldirection}: 15px;
  }
  .icon--pure-privilege {
    padding: 5px;
    color: $color-pure-privilege;
  }
  .sticky-livechat__cta {
    display: none;
  }
  div[data-lp-event],
  a[data-lp-event] {
    display: none;
  }
}
